<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>
        {{ $t("configurate", { name: $tc("artist") }) }}
      </v-card-title>
      <v-card-subtitle
        v-if="tattooer.user.first_name"
        class="mt-0 text-center primary--text"
      >
        {{ tattooer.user.first_name }}
        {{ tattooer.user.last_name }}
      </v-card-subtitle>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row align="center">
          <template v-if="ts.from > today">
            <v-col cols="6">{{ $t("change_start") }}</v-col>
            <v-col cols="6">
              <ChangeTattooerStart
                :tattooer="tattooer"
                @update="$emit('update')"
              />
            </v-col>
          </template>
          <v-col cols="6">{{ $t("timetable", { name: "" }) }}</v-col>
          <v-col cols="6">
            <EditTattooerTimetable
              :tattooer_id="tattooer.id"
              :studio_id="$store.state.auth.user.studio.id"
              :ref="`timetable-${tattooer.id}`"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">{{ $t("limitation", { name: "" }) }}</v-col>
          <v-col cols="6">
            <Limitations :tattooer="tattooer" @update="$emit('update')" />
          </v-col>
        </v-row>

        <v-row
          align="center"
          v-if="$store.getters['auth/getSetting']('box_active') == 1"
        >
          <v-col cols="6">{{ $t("boxes.boxs", { name: "" }) }}</v-col>
          <v-col cols="6">
            <Boxes
              :tattooer="tattooer"
              :list="false"
              @update="$emit('update')"
            />
          </v-col>
        </v-row>

        <v-row
          align="center"
          v-if="$store.getters['auth/getSetting']('comisions_active') === 1"
        >
          <v-col cols="6">
            {{ $t("expenses.commissions", { name: "" }) }}
          </v-col>
          <v-col cols="6">
            <commissions :tattooer="tattooer" />
          </v-col>
        </v-row>
        <v-row
          align="center"
          v-if="$store.getters['auth/getSetting']('payment_appointment') == 1"
        >
          <v-col cols="6">{{ $t("tattooers.token_payment") }}</v-col>
          <v-col cols="6">
            <TokenPayments
              :id="tattooer.id"
              :list="false"
              @update="$emit('update')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"> {{ $t("own_services") }} </v-col>
          <v-col cols="6">
            <OwnService
              :tattooer_id="ts.tattooer_id"
              :studio_id="ts.studio_id"
              :value="ts.own_services"
              _key="own_services"
              @update="$emit('update')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">{{ $t("asign_services") }}</v-col>
          <v-col cols="6" class="px-6">
            <Services :card_active="false" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
export default {
  name: "Configuration",
  props: ["tattooer"],

  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  data() {
    return { dialog: false, today: null };
  },
  components: {
    Commissions: () => import("@/components/tattooer/Commissions"),
    TokenPayments: () =>
      import("@/components/user/settings/profile/TokenPayment"),
    Boxes: () => import("@/components/user/settings/profile/Boxes"),
    EditTattooerTimetable: () =>
      import("@/components/user/timetable/Configuration"),
    Limitations: () => import("@/components/tattooer/Limitations"),
    ChangeTattooerStart: () => import("@/components/tattooer/ChangeStart"),
    OwnService: () => import("@/components/tattooer/OwnService"),
    Services: () => import("@/components/user/settings/configuracion/Services"),
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },

  computed: {
    ts() {
      let ts = this.tattooer.tattooer_studios.find(
        (ts) => ts.studio_id === this.$store.state.auth.user.studio.id
      );
      return ts;
    },
  },
};
</script>
